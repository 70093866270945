import {redirect, type LoaderArgs} from '@shopify/remix-oxygen';
import type {Product} from '@shopify/hydrogen/storefront-api-types';
import invariant from 'tiny-invariant';

import {IsStoreVisibleFilter, useGetProductsQuery} from 'app/gql/generated';
import {QueryClient} from '@tanstack/react-query';
import {Storefront} from '~/lib/type';
import {getLoaderExpertName} from '~/modules/Common/util';
import {
  getExpertApiTheme,
  getExpertProductIds,
} from '~/modules/Expert/utils.server';
import {getIsValidShopifyExternalId} from '../utils/productUtils';
import {
  getShopifyProductsByIds,
  getShopifyProductsFromSynthetics,
  getWaywardProductsById,
} from '../utils/product.utils.server';

export async function loader({
  context: {storefront, client},
  params,
  request,
}: LoaderArgs) {
  const expertName = getLoaderExpertName(params, request);
  if (!expertName) {
    return redirect('/404');
  }

  if (expertName === 'newbrand') {
    return redirect('/brand-user/login');
  }

  if (expertName === 'demostore') {
    return redirects('/expert/login');
  }

  const [theme, {products, shopifyProducts}] = await Promise.all([
    getExpertApiTheme(params, request, client),
    getAllProducts(expertName, storefront, {client}),
  ]);

  invariant(shopifyProducts, 'No data returned from Shopify API');

  return {
    products,
    shopifyProducts,
    isSubdomain: !params.expertName,
    expertName,
    storeName: theme?.store?.name || '',
    hero: theme?.store?.hero || '',
  };
}

export async function getAllProducts(
  expertName: string,
  storefront: Storefront,
  options?: {
    exclude?: string;
    limit?: number;
    internal?: boolean;
    client?: QueryClient;
    page?: number;
    filterVisible?: boolean;
    cap?: number;
  },
) {
  const client = options?.client || new QueryClient();

  const ids = await getExpertProductIds(
    client,
    expertName,
    options?.filterVisible
      ? IsStoreVisibleFilter.OnlyVisible
      : IsStoreVisibleFilter.All,
  );
  const page = options?.page || 1;
  const start = options?.limit ? (page - 1) * options.limit : 0;
  const end = options?.limit ? page * options.limit : Infinity;

  const allIds = ids
    .filter(({id}) => (options?.exclude ? id !== options.exclude : true))
    .slice(start, end);

  const shopifyIds = allIds
    .map(({externalId}) => externalId)
    .filter(getIsValidShopifyExternalId);

  const results = await Promise.allSettled([
    getShopifyProductsByIds(shopifyIds, storefront),
    getWaywardProductsById(
      expertName,
      allIds.map(({id}) => id),
      client,
      options,
    ),
  ]);

  const [shopifyProducts, products] = results.map((p) =>
    p.status === 'fulfilled' ? p.value : p.reason,
  );

  const additionShopifyProducts = await getShopifyProductsFromSynthetics(
    products,
    storefront,
  );
  shopifyProducts.nodes = shopifyProducts.nodes.concat(
    additionShopifyProducts.nodes,
  );

  return {
    products,
    shopifyProducts: shopifyProducts as {
      nodes: Product[];
    },
    maxProducts: ids.length,
    expertIds: ids.map(({id}) => id),
  };
}

export async function getInternalProductsByExpert(
  expertName: string,
  client?: QueryClient,
) {
  const queryClient = client || new QueryClient();

  const ids = await getExpertProductIds(queryClient, expertName);
  return await getInternalProductsById(
    queryClient,
    expertName,
    ids.map(({id}) => id),
  );
}

export async function getInternalProductsById(
  client: QueryClient,
  expertName: string,
  productIds: string[],
) {
  try {
    const productFetcherVars = {
      expertName,
      productIds,
    };
    const resp = await client.fetchQuery(
      useGetProductsQuery.getKey(productFetcherVars),
      useGetProductsQuery.fetcher(productFetcherVars),
    );
    return resp.getProducts;
  } catch (e) {
    console.error('error', e);
    return [];
  }
}

export type AllProductsLoader = typeof loader;
